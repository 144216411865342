import "./partners.css";
import { useGetPartnersQuery } from "../../Api/api";
import { useEffect, useState } from "react";

const Partners = () => {
  const { data: partners, isFetching } = useGetPartnersQuery();
  const [servicesDetails, setServicesDetails] = useState(partners);
  const img_300 = "http://drive.google.com/thumbnail?id=";

  useEffect(() => {
    setServicesDetails(partners);
    console.log(servicesDetails);
  }, [servicesDetails, partners]);
  if (isFetching) return "loading";

  return (
    <>
      <section id="partners">
        <div className="service-container">
          <div className="service-title">
            <h2>Businesses We Partner With</h2>

            <h3>Business</h3>
          </div>

          <div className="service-row">
            {partners &&
              partners.map((partners) => (
                <div
                  className=" my-service"
                  key={partners.id}
                  data-aos="zoom-in-up"
                  data-aos-duration="1500"
                >
                  <div className="ser-back">
                    <img src={`${img_300}${partners.icon_image}&sz=s1000`} alt="" />>
                  </div>
                  <h4 className="web">{partners.service_name}</h4>
                  <p className="partners-info">{partners.service_description}</p>
                  {/* <h6 className="learn-more">{partners.learn_more}</h6> */}
                  <div class="shadow-icon">
                    <i class={partners.shadow_icon}></i>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Partners;
