import { configureStore } from "@reduxjs/toolkit";
import {
  HomeDetails,
  AboutMe,
  Services,
  Progress,
  Projects,
  Contacts,
  SocialMedia,
  LanguagesIcons,
  Partners,
} from "../Api/api";

// Setup the store and include the RTK Query middleware
export default configureStore({
  reducer: {
    [HomeDetails.reducerPath]: HomeDetails.reducer,
    [AboutMe.reducerPath]: AboutMe.reducer,
    [Services.reducerPath]: Services.reducer,
    [Progress.reducerPath]: Progress.reducer,
    [Projects.reducerPath]: Projects.reducer,
    [Contacts.reducerPath]: Contacts.reducer,
    [SocialMedia.reducerPath]: SocialMedia.reducer,
    [LanguagesIcons.reducerPath]: LanguagesIcons.reducer,
    [Partners.reducerPath]: Partners.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      HomeDetails.middleware,
      AboutMe.middleware,
      Services.middleware,
      Progress.middleware,
      Projects.middleware,
      Contacts.middleware,
      SocialMedia.middleware,
      LanguagesIcons.middleware,
      Partners.middleware
    ),
});
